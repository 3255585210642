import React from 'react';
import { Text } from 'rebass';
import { titleList } from './css/positions.css';
import CareerPosition from '../../../components/layout/Career/CareerPosition';
import { ListItemSquare } from '../../../components/layout/ListItemComponents/ListItemSquare';

const DigitalMarketingManager: React.FC = () => {
  return (
    <CareerPosition positionTitle="Digital Marketing Specialist">
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Znanja i veštine
      </Text>
      <ListItemSquare text={'Minimum 3-4 godina radnog iskustva u digitalnom marketingu'} />
      <ListItemSquare
        text={
          'Iskustvo u radu sa digital marketing alatima - Google Analytics, Google Tag Manager, Search Console, Facebook Pixel, Semrush'
        }
      />
      <ListItemSquare text={'Odlične analitičke sposobnosti'} />
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Bonus (uvažavamo dodatno)
      </Text>
      <ListItemSquare text={'Iskustvo sa drugim alatima za analitiku (HotJar, Google Data Studio, Mixpanel)'} />
      <ListItemSquare text={'Iskustvo u radu sa emailovima i drugim kanalima komunikacije'} />
      <Text mt={'30px'} mb={'10px'} css={titleList} textAlign="left">
        Odgovornosti i zaduženja
      </Text>
      <ListItemSquare text={'Kreira i aktivno prati oglasne kampanje na digitalnim kanalima (SM i SEM)'} />
      <ListItemSquare text={'Kreira i aktivno prati retargeting oglasne jedinice (SM i SEM)'} />
      <ListItemSquare text={'Planira i izvršava AB (split) testove oglasnih jedinica za povećanje broja konverzija'} />
      <ListItemSquare text={'Meri i izveštava o uspesima kampanja digitalnog marketinga na mesečnom i kvartalnom nivou (ROI, KPI)'} />
      <ListItemSquare
        text={'Učestvuje u razvoju digitalnih marketinških planova i postavljanju ciljeva i metrika sa ostalim članovima Marketing tima'}
      />
      <ListItemSquare
        text={'Učestvuje u kreiranju koncepta oglasnih jedinica, kao i pripremi sadržaja i produkcije sa ostalim članovima Marketing tima'}
      />
      <ListItemSquare text={'Učestvuje u postavljanju generalnih marketing strategija sa Marketing i Growth timom'} />
      <ListItemSquare text={'Prati najnovije trendove i tehnologije u digitalnom marketingu'} />
      <ListItemSquare text={'Odgovara za primenu i komuniciranje strategije, ideja i planova drugim članovima tima '} />
      <ListItemSquare text={'Odgovara Direktoru sektora marketinga'} />
    </CareerPosition>
  );
};

export default DigitalMarketingManager;
